<template>
  <div />
</template>

<script>
export default {
  name: 'Survey',
  created() {
    this.$nextTick(() => {
      // const targetUrl = 'https://qmsbzln5j6e.typeform.com/to/' + this.$route.params.surveyId
      const url = decodeURIComponent(window.location.href)
      const objUrl = new URL(url)
      const targetUrl = objUrl.searchParams.get('targetUrl')
      try {
        const uid = this.$store.state.user.userInfo.user.uid
        window.location = targetUrl + '#uuid=' + uid
      } catch (error) {
        if (targetUrl) { window.location = targetUrl }
      }
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
